import React, {Component} from 'react'
import firebase from 'firebase'
import {withOktaAuth} from '@okta/okta-react';
import config from './../config'
import {Link} from 'react-router-dom'
import constants from '../constants'
import {connect} from 'react-redux'
import {signOut, windowScrollChanged} from '../actions'



class Toolbar extends Component {

  constructor( props ) {
    super( props );
  }

  componentDidMount () {
    window.addEventListener('scroll', e => {
      this.props.windowScrollChanged(window.scrollY)
    })
  }

  // async componentDidUpdate() {
  //   this.checkUser();
  // }

  // signMeOut = async () => {
  //   const {authState, authService} = this.props;
  //
  //   console.log( "Signing out user with token " + authState.idToken + " and cleaning up local browser storage..." )
  //   // Read idToken before local session is cleared
  //   // const idToken = authState.idToken;
  //   // const { issuer, redirectUri } = config.oidc
  //
  //   localStorage.clear();
  //   this.props.signOut();
  //   await firebase.auth().signOut();
  //   // const {history} = this.props
  //   // history.push("/login")
  //
  //   await authService.logout('/')
  //   localStorage.clear()
  //
  //   // window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=http://localhost:3000/`
  // }

  signMeOut = async () => {
    const {authState, authService} = this.props;

    const issuer = `${config.oidc.issuer}`;
    const redirectUri = `${window.location.origin}/info`;

    console.log( "LOGOUT: variables set: ( issuer: " + issuer + " | redirectUri: " + redirectUri + " )" )

    console.log( "Signing out OKTA user with token " + authState.idToken + ", signing out internal Firebase user and cleaning up local browser storage..." )
    // Read idToken before local session is cleared
    // const idToken = authState.idToken;
    // const { issuer, redirectUri } = config.oidc

    // localStorage.clear();
    this.props.signOut();
    console.log( "Sign out process via props done, signing out users (FB and Okta) now..." )
    await firebase.auth().signOut();
    // const {history} = this.props
    // history.push("/login")

    console.log( "read id-token before logging out Okta User..." )
    const idToken = authState.idToken;
    await authService.logout('/')
    console.log( "Firebase and Okta user signed out, clearing the local storage..." )
    localStorage.clear()
    console.log( "Local Storage cleared..." )

    console.log( "Clearing Remote session for id-token `" + idToken + "`..." )
    // window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=http://localhost:3000/`
    window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`;
  }

  renderBackLink = path => {
    return (
      <Link to={path} className="toolbar__back">
        <img src={constants.icons.chevronLeft.path} alt="back"/>
      </Link>
    )
  }
  runnableBeforeLogout;

  render () {
    const {backLink, title, windowScrolled} = this.props
    const className = `toolbar ${backLink ? 'with-back-link' : ''} ${windowScrolled ? 'scrolled' : ''}`

    return (
      <div className={className}>
        {backLink && this.renderBackLink(backLink)}
        {title && <h4 className="toolbar__title">{title}</h4>}
        <button className="toolbar__signout button-anchor" onClick={() => this.signMeOut()}>sign out</button>
        {/*this.props.runnableBeforeLogout = this.signMeOut*/}
        {/*<Logout />*/}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  windowScrolled: state.windowScrolled
})

// const mergeProps = (stateProps, dispatchProps, ownProps) => {
//   return Object.assign({}, ownProps, {
//     redux: {
//       state: stateProps,
//       actions: dispatchProps
//     }
//   });
// };

const mapDispatchToProps = {
  signOut,
  windowScrollChanged
}

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(Toolbar));
