import React from 'react'
import constants from '../constants'

const FactorTitle = ({name, title, classification, isPriority}) => (
  <div className="factor-title">
    <div className={`factor-title-icon icon-button small icon-button--${classification}`}>
      <img src={constants.icons[name].path} alt={`${title} icon`} />
    </div>
    <h3>{title}</h3>
    {
      isPriority &&
      <img className="priority-icon" src={constants.icons.starFilled.path} alt="priority" />
    }
  </div>
)

export default FactorTitle
