import React, { Component } from 'react'
import { connect } from 'react-redux'
import camelCase from 'camel-case'
import { toast } from 'react-toastify'
import ReactMarkdown from 'react-markdown'

import { setPriority, unsetPriority } from '../actions'

import constants from '../constants'

class Card extends Component {
  renderSetPriorities () {
    const {priorities, subFactor, unsetPriority} = this.props
    const isPriority = priorities.find(x => x.name === subFactor.name) !== undefined

    return isPriority
      ? <a className="text-subdued" onClick={() => unsetPriority(subFactor)}>
        <img src={constants.icons.starFilled.path} alt="priority"/>
        remove priority
      </a>
      : <a className="text-subdued" onClick={() => this.trySetPriorities(subFactor)}>
        <img src={constants.icons.starHollow.path} alt="priority"/>
        make a priority
      </a>
  }

  trySetPriorities (subFactor) {
    const {priorities, setPriority} = this.props

    if (priorities.length >= 3) {
      return toast.warn('We\'ve limited the priorities feature to 3 in order to help you focus on what\'s most important', {
        closeButton: false
      })
    }

    setPriority(subFactor)
  }

  render () {
    const {title, classification, description} = this.props

    return (
      <div className="card">
        <div className={`card__classification card__classification--${camelCase(classification)}`}/>
        <div className="card__details">
          <div className="card__title-block">
            <h4>{title}</h4>
            {this.renderSetPriorities()}
          </div>
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  priorities: state.priorities
})

const mapDispatchToProps = {
  setPriority,
  unsetPriority
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)
