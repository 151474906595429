import React from 'react'
import { connect } from 'react-redux'

import FactorLink from './FactorLink'
import constants from '../constants'

const FactorLinks = ({scores, content, priorities}) => {
  const {icons, factors} = constants

  const transformByClassification = (accum, factor) => {
    if (!factor.classification) return accum
    let index
    if (factor.classification === constants.DRIVER) index = 0
    if (factor.classification === constants.DIVIDER) index = 1
    if (factor.classification === constants.DRAINER) index = 2
    accum[index].push(factor)
    return accum
  }

  const flatten = (accum, factor) => {
    return [...accum, ...factor]
  }

  const factorsByClassification = Object.values(scores)
    .reduce(transformByClassification, [[], [], []])
    .reduce(flatten, [])

  return (
    <div className="factor-links">
      {factorsByClassification.map(factor => {
        if (!content.factors[factor.name]) {
          throw new Error(`Could not find factor content for ${factor.name}`)
        }

        return (<FactorLink
          key={factor.name}
          title={content.factors[factor.name].title} // FIXME null checks here 'cannot read .title of undefined'
          path={factors[factor.name].path}
          classification={scores[factor.name].classification}
          iconPath={icons[factor.name].path}
          isPriority={priorities.find(p => p.parentFactor === factor.name)}
        />)
      })
      }
    </div>
  )
}

const mapStateToProps = state => ({
  priorities: state.priorities
})

export default connect(mapStateToProps)(FactorLinks)
