import React, { Component } from 'react'
import { connect } from 'react-redux'
import camelCase from 'camel-case'
import Card from './Card'
import constants from '../constants'

class SubFactor extends Component {

  description = (classification, content) => {
    if (!(classification && content)) return null
    const description = content[`${camelCase(classification)}Description`]
    if (!description) return null

    return description
  }

  translateNonStandardClassifications = classification => {
    switch (classification) {
      case constants.LOWER_RISK:
        return constants.DRIVER

      case constants.HIGHER_RISK:
        return constants.DRAINER

      case constants.FINANCIALLY_CONFIDENT:
        return constants.DRIVER

      case constants.FINANCIALLY_STRAINED:
        return constants.DRAINER

      default:
        return classification
    }
  }

  render () {
    const { name, parentFactor, classification, content } = this.props
    const subFactorContent = content && content.factors[parentFactor] && content.factors[parentFactor].subFactors[name]
    const title = subFactorContent && subFactorContent.title
    const description = this.description(this.translateNonStandardClassifications(classification), subFactorContent)

    return (
      <div className="sub-factor">
        <Card title={title} classification={classification} description={description} subFactor={{name, parentFactor}} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  content: state.content
})

export default connect(mapStateToProps)(SubFactor)
