export default {
  prioritiesMax: 3,
  factors: {
    connect: {name: 'connect', path: '/connect'},
    work: {name: 'work', path: '/work'},
    mindfulAction: {name: 'mindfulAction', path: '/mindful-action'},
    grow: {name: 'grow', path: '/grow'},
    rest: {name: 'rest', path: '/rest'},
    nourish: {name: 'nourish', path: '/nourish'},
    stressMastery: {name: 'stressMastery', path: '/stress-mastery'},
    move: {name: 'move', path: '/move'}
  },
  routes: {
   HOME: '/',
   PROFILE: '/profile',
   LEARNING_LOCKER: '/learning-locker',
   INFO: '/info',
   RESOURCES: 'https://www.bennybutton.com/resources',
   RESOURCES_LINK: '//www.bennybutton.com/resources',
   REA_EAP: '//rea.to/eap'
  },
  icons: {
    connect: {path: '/icons/icon-connect.svg'},
    work: {path: '/icons/icon-work.svg'},
    mindfulAction: {path: '/icons/icon-mindfulAction.svg'},
    grow: {path: '/icons/icon-grow.svg'},
    rest: {path: '/icons/icon-rest.svg'},
    nourish: {path: '/icons/icon-nourish.svg'},
    stressMastery: {path: '/icons/icon-stressMastery.svg'},
    move: {path: '/icons/icon-move.svg'},
    chevronLeft: {path: '/icons/icon-chevron-left.svg'},
    starHollow: {path: '/icons/icon-star-hollow.png'},
    starFilled: {path: '/icons/icon-star-filled.png'},
    navMyProfile: {path: '/icons/icon-nav-my-profile.svg'},
    navLearningLocker: {path: '/icons/icon-nav-learning-locker.svg'},
    navInfo: {path: '/icons/icon-nav-info.svg'},
    info: {path: '/icons/icon-info.svg'},
    speechBubble: {path: '/icons/icon-speech-bubble.svg'},
    x: {path: '/icons/icon-x.svg'},
    toolbox: {path: '/icons/toolbox-light.svg'}
  },
  images: {
    logo: {path: '/images/logo.svg'}
  },
  READ_MORE: 'Read more',
  CONTENT_REPLACEMENT_TOKEN: '{x}',
  EMAIL_USED_IN_SURVEY_MESSAGE: 'Here\'s the email you used in the survey',
  SCORES: 'scores',
  CONTENT: 'content',
  DRAINER: 'Drainer',
  DIVIDER: 'Divider',
  DRIVER: 'Driver',
  HIGHER_RISK: 'Higher risk',
  LOWER_RISK: 'Lower risk',
  FINANCIALLY_STRAINED: 'Financially strained',
  FINANCIALLY_CONFIDENT: 'Financially confident or content',
  WELLBEING_INFO_INTRO: 'Your personalised diagnostic reveals three key classifications for each factor:',
  WELLBEING_INFO_DRIVER: 'Driver - indicates a strength',
  WELLBEING_INFO_DIVIDER: 'Divider - indicates room for improvement',
  WELLBEING_INFO_DRAINER: 'Drainer - indicates a weakness with significant room for improvement',
  NO_PRIORITIES_TITLE: 'You haven\'t set any priorities yet',
  NO_PRIORITIES_MESSAGE: 'You can set wellbeing factors as priorities in your profile – we\'ve limited it to 3 at a time to keep you focused!',
  ALL: 'All',
  PRIORITIES: 'My priorities'
}
