import React from 'react'
import {DateTime} from "luxon";

const ErrorModalHtml = ({message, stack}) => {
  const now = DateTime.local()
  let renderedMessage = {__html: message};
  return (
    <div className="error-modal">
      <div className="error-modal__background" />
      <div className="error">
        <div className="error__message">
          <h3>Oooops, unfortunately something did not work as expected.</h3>
          <div><br/></div>
        </div>
        <div className="error__message" dangerouslySetInnerHTML={renderedMessage}/>
        <div className="error__message"><br/>
          <small>{now.toISO()}</small>
        </div>
        {stack &&
        <div>
          <br/>
          <p>A detailed report of this error has been sent to the developers.</p>
          <p>Thank you for your patience.</p>
          <small>{now.toISO()}</small>
          <pre>{stack}</pre>
        </div>
        }
      </div>
    </div>
  )
}

export default ErrorModalHtml
