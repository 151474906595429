import React from 'react'

const ArticleCard = ({article}) => {
  const thumbnail = article.thumbnailImage
    ? {backgroundImage: `url(${article.thumbnailImage.file.url})`}
    : {}

  return (
    <a className="article-card" target="_blank" href={article.externalUrl}>
      <div className="article-card__thumbnail" style={thumbnail}/>
      <div className="article-card__caption">
        {article.title}
      </div>
    </a>
  )
}

export default ArticleCard
