export default {
  APP_READY_CHANGED: 'APP_READY_CHANGED',
  FIREBASE_USER_CHANGED: 'FIREBASE_USER_CHANGED',
  SIGN_OUT: 'SIGN_OUT',
  FETCH_CONTENT: 'FETCH_CONTENT',
  FETCH_CONTENT_SUCCESS: 'FETCH_CONTENT_SUCCESS',
  FETCH_CONTENT_FAILED: 'FETCH_CONTENT_FAILED',
  FETCH_USER_DATA: 'FETCH_USER_DATA',
  FETCH_OKTA_USER_DATA: 'FETCH_OKTA_USER_DATA',
  OKTA_USER_AUTHENTICATED: 'OKTA_USER_AUTHENTICATED',
  CREATE_OKTA_USER: 'CREATE_OKTA_USER',
  CREATE_OKTA_USER_SUCCESS: 'CREATE_OKTA_USER_SUCCESS',
  CREATE_OKTA_USER_FAILED: 'CREATE_OKTA_USER_FAILED',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_FAILED: 'FETCH_USER_DATA_FAILED',
  FETCH_USER_PRIORITIES: 'FETCH_USER_PRIORITIES',
  FETCH_USER_PRIORITIES_SUCCESS: 'FETCH_USER_PRIORITIES_SUCCESS',
  FETCH_USER_PRIORITIES_FAILED: 'FETCH_USER_PRIORITIES_FAILED',
  UPDATE_USER_PRIORITIES: 'UPDATE_USER_PRIORITIES',
  UPDATE_USER_PRIORITIES_SUCCESS: 'UPDATE_USER_PRIORITIES_SUCCESS',
  UPDATE_USER_PRIORITIES_FAILED: 'UPDATE_USER_PRIORITIES_FAILED',
  FACTOR_DEFINITION_EXPANDED: 'FACTOR_DEFINITION_EXPANDED',
  FACTOR_DEFINITION_COLLAPSED: 'FACTOR_DEFINITION_COLLAPSED',
  WINDOW_SCROLL_CHANGED: 'WINDOW_SCROLL_CHANGED',
  WELLBEING_INFO_TOGGLED: 'WELLBEING_INFO_TOGGLED',
  SET_PRIORITY: 'SET_PRIORITY',
  UNSET_PRIORITY: 'UNSET_PRIORITY',
  SET_ACTIVE_LEARNING_LOCKER_TAB: 'SET_ACTIVE_LEARNING_LOCKER_TAB'
}
