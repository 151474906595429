module.exports = {
  firebase: {
    apiKey: "AIzaSyCXXWA01pn2KPaExJToT6HdkdQYwlzN2wc",
    authDomain: "benny-button-dev-401d6.firebaseapp.com",
    databaseURL: "https://benny-button-dev-401d6.firebaseio.com",
    projectId: "benny-button-dev-401d6",
    storageBucket: "benny-button-dev-401d6.appspot.com",
    messagingSenderId: "259737826054",
  },
  oidc: {
    clientId: '0oa1hqgoujlaMtYWd1d8', // REA OKTA PROD (rea.okta.com)
    // clientId: '0oat8kruq64P4u5K70h7', // REA OKTA DEV (rea.oktapreview.com)
    // clientId: '0oajz1ab72Ax2ImRb4x6', // localhost
    // clientId: '0oao929fwBTRwbgZz4x6', // rea-profiler.bennybutton.com test
    redirectUri: 'https://rea-profiler.bennybutton.com/implicit/callback', // rea-profiler.bennybutton.com (PROD & DEV)
    // redirectUri: 'http://localhost:8080/implicit/callback', // localhost docker tuasonsoftware
    // redirectUri: 'http://localhost:3000/implicit/callback', // localhost tuasonsoftware and REA OKTA DEV
    // redirectUri: 'https://rea-profiler-dot-benny-button-dev-401d6.uc.r.appspot.com/implicit/callback', // tuasonsoftware
    issuer: 'https://rea.okta.com/oauth2/default', // REA OKTA PROD (rea.okta.com)
    // issuer: 'https://rea.oktapreview.com/oauth2/default', // REA OKTA DEV (rea.oktapreview.com)
    // issuer: 'https://dev-812847.okta.com/oauth2/default',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: false,
  },
  oktaUsersBasePass: 'LetMe!nN0w',
  reaFormId: 'ovMDsc'
}
