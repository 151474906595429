import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import constants from '../constants'
import { withOktaAuth } from '@okta/okta-react';

class Nav extends Component {

  render () {

    const { authStatus } = this.props

    return (
      <nav className="nav">
        <ul>
          <li>
            <Link to={constants.routes.PROFILE}>
              <div className="icon-container">
                <img src={constants.icons.navMyProfile.path} alt="back"/>
              </div>
              <span>My Profile</span>
            </Link>
          </li>
          <li>
            <Link to={constants.routes.LEARNING_LOCKER}>
              <div className="icon-container">
                <img src={constants.icons.navLearningLocker.path} alt="back"/>
              </div>
              <span>Learning Locker</span>
            </Link>
          </li>
          <li>
            <Link to={constants.routes.INFO}>
              <div className="icon-container">
                <img src={constants.icons.navInfo.path} alt="back"/>
              </div>
              <span>Info</span>
            </Link>
          </li>
          <li>
            <Link target="_blank" to={constants.routes.RESOURCES_LINK} >
              <div className="icon-container">
                <img src={constants.icons.toolbox.path} id="resources-icon" alt="back"/>
              </div>
              <span>Resources</span>
            </Link>
          </li>
          <li>
            <Link target="_blank" to={constants.routes.REA_EAP} >
              <div className="icon-container">
                <img src={constants.icons.toolbox.path} id="resources-icon" alt="back"/>
              </div>
              <span>REA EAP</span>
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default withOktaAuth(Nav)
