import React from 'react'

const EmptyState = ({title, message}) => {
  return (
    <div className="empty-state">
      <h4 className="empty-state__title">{title}</h4>
      <p className="empty-state__message">{message}</p>
    </div>
  )
}

export default EmptyState
