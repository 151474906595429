import React from 'react'
import ReactMarkdown from 'react-markdown'

const ContentGeneral = ({title, description, backgroundImage}) => (
  <div className="content-general">
    <h4 className="content-general__title">{title}</h4>
    <ReactMarkdown className="content-general__description">{description}</ReactMarkdown>
    {backgroundImage && <div className="content-general__image" style={backgroundImage} />}
  </div>
)

export default ContentGeneral
