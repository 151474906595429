import React from 'react'
import LoadingSpinner from './LoadingSpinner'

const LoadingScreen = () => (
  <div>
    <div className="loading-fullscreen">
        <LoadingSpinner />
    </div>
    {/*<div>*/}
    {/*  <h3>Authenticating and Loading Benny Button Profiler...</h3>*/}
    {/*</div>*/}
  </div>
)

export default LoadingScreen
