import React from 'react'
import constants from '../constants'

const WellbeingInfo = () => (
  <div className="wellbeing-info">
    <p className="wellbeing-info__intro">{constants.WELLBEING_INFO_INTRO}</p>
    <ul className="wellbeing-info__list">
      <li className="list-item">
        <div className="classification-dot classification-dot--driver"/>
        <span>{constants.WELLBEING_INFO_DRIVER}</span>
      </li>
      <li className="list-item">
        <div className="classification-dot classification-dot--divider"/>
        <span>{constants.WELLBEING_INFO_DIVIDER}</span>
      </li>
      <li className="list-item">
        <div className="classification-dot classification-dot--drainer"/>
        <span>{constants.WELLBEING_INFO_DRAINER}</span>
      </li>
    </ul>
  </div>
)

export default WellbeingInfo
