module.exports = {
  firebase: {
    apiKey: "AIzaSyDCsfjcn89AmCoKAhYgkV_AjneGsnDut6k",
    authDomain: "benny-button-staging.firebaseapp.com",
    databaseURL: "https://benny-button-staging.firebaseio.com",
    projectId: "benny-button-staging",
    storageBucket: "benny-button-staging.appspot.com",
    messagingSenderId: "565938036440"
  }
}
