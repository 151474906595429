import { call, put, takeLatest, all } from 'redux-saga/effects'

import api from '../api'
import Types from '../types'
import {
  createOktaUserSuccess,
  createOktaUserFailed,
  fetchContentFailed,
  fetchContentSuccess,
  fetchUserDataFailed,
  fetchUserDataSuccess,
  fetchUserPrioritiesSuccess,
  fetchUserPrioritiesFailed,
  updateUserPrioritiesSuccess,
  updateUserPrioritiesFailed,
  setPriority
} from '../actions'
import constants from '../constants'

export function * fetchContent () {
  try {
    const {data} = yield call(api.fetchContent)
    yield put(fetchContentSuccess(data))
    yield localStorage.setItem(constants.CONTENT, JSON.stringify(data))
  } catch (error) {
    yield put(fetchContentFailed(error))
  }
}

export function * fetchUserData ({formId}) {
  try {
    const {data} = yield call(() => api.fetchUserData(formId))
    yield put(fetchUserDataSuccess(data))
    yield localStorage.setItem(constants.SCORES, JSON.stringify(data))
  } catch (error) {
    yield put(fetchUserDataFailed(error))
  }
}


export function * fetchOktaUserData ({formId, employeeId}) {
  try {
    const {data} = yield call(() => api.fetchOktaUserData(formId, employeeId))
    yield put(fetchUserDataSuccess(data))
    yield localStorage.setItem(constants.SCORES, JSON.stringify(data))
  } catch (error) {
    yield put(fetchUserDataFailed(error))
  }
}


export function * createOktaUser ({email, password, employeeId}) {
  try {
    // const {user} = yield call( () => api.createOktaUser(email, password, employeeId) )
    let fbUser = yield call( () => api.createOktaUser(email, password, employeeId) )
    // yield call(() => api.createOktaUser(email, password))
    if ( fbUser )
      yield put(createOktaUserSuccess())
    else
      yield put(createOktaUserFailed())
  } catch (error) {
    // yield put({type: "USER_FETCH_FAILED", message: error.message});
    console.error(error.message)
    yield put(createOktaUserFailed(error))
  }
}

export function * fetchUserPriorities () {
  try {
    const {data} = yield call(api.fetchUserPriorities)
    yield localStorage.setItem(constants.PRIORITIES, JSON.stringify(data.priorities))
    yield put(fetchUserPrioritiesSuccess(data))
  } catch (error) {
    console.error(error)
    yield put(fetchUserPrioritiesFailed(error))
  }
}

export function * setPriorityInStorageAndFirebase (action) {
  try {
    const prioritiesString = yield localStorage.getItem(constants.PRIORITIES) || '[]'
    const priorities = JSON.parse(prioritiesString)
    const newPriorities = (priorities.length === constants.prioritiesMax)
      ? priorities.slice(1, constants.prioritiesMax).concat([action.subFactor])
      : priorities.concat([action.subFactor])
    yield localStorage.setItem(constants.PRIORITIES, JSON.stringify(newPriorities))

    // Update in firebase
    const {data} = yield call(api.updateUserPriorities, newPriorities)
    yield put(updateUserPrioritiesSuccess(data))
  } catch (error) {
    console.error(error)
    yield put(updateUserPrioritiesFailed(error))
  }
}

export function * unsetPriorityInStorageAndFirebase (action) {
  try {
    const prioritiesString = yield localStorage.getItem(constants.PRIORITIES) || '[]'
    const priorities = JSON.parse(prioritiesString)
    const newPriorities = priorities.filter(subFactor => subFactor.name !== action.subFactor.name)
    yield localStorage.setItem(constants.PRIORITIES, JSON.stringify(newPriorities))

    // Update in firebase
    const {data} = yield call(api.updateUserPriorities, newPriorities)
    yield put(updateUserPrioritiesSuccess(data))
  } catch (error) {
    console.error(error)
    yield put(updateUserPrioritiesFailed(error))
  }
}

export function * loadPrioritiesFromStorage () {
  try {
    const prioritiesString = yield localStorage.getItem(constants.PRIORITIES) || '[]'
    const priorities = JSON.parse(prioritiesString)
    yield all(priorities.map(subFactor => put(setPriority(subFactor))))
  } catch (error) {
    console.error(error)
  }
}

function * watchCreateOktaUser () {
  yield takeLatest(Types.CREATE_OKTA_USER, createOktaUser)
}


function * watchFetchContent () {
  yield takeLatest(Types.FETCH_CONTENT, fetchContent)
}

function * watchFetchUserData () {
  yield takeLatest(Types.FETCH_USER_DATA, fetchUserData)
}

function * watchFetchOktaUserData () {
  yield takeLatest(Types.FETCH_OKTA_USER_DATA, fetchOktaUserData)
}

function * watchFetchUserPriorities () {
  yield takeLatest(Types.FETCH_USER_DATA_SUCCESS, fetchUserPriorities)
}

function * rootSaga () {
  yield all([
    call(watchCreateOktaUser),
    call(watchFetchUserData),
    call(watchFetchOktaUserData),
    call(watchFetchContent),
    call(watchFetchUserPriorities),
    takeLatest(Types.FETCH_USER_PRIORITIES_SUCCESS, loadPrioritiesFromStorage),
    takeLatest(Types.SET_PRIORITY, setPriorityInStorageAndFirebase),
    takeLatest(Types.UNSET_PRIORITY, unsetPriorityInStorageAndFirebase)
  ])
}

export default rootSaga
