import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useOktaAuth} from '@okta/okta-react';
import {
  createOktaUser,
  createOktaUserSuccess,
  fetchContentSuccess,
  fetchOktaUserData,
  fetchUserDataSuccess,
  firebaseUserChanged,
  signOut
} from './../actions'
import Toolbar from '../components/Toolbar'
import WellbeingChart from "../components/WellbeingChart";
import FactorLinks from "../components/FactorLinks";
import LoadingScreen from "../components/LoadingFullscreen";

const Profile = ( props ) => {
  const { authState, authService } = useOktaAuth();
  // const [ userInfo, setUserInfo ] = useState(null);
  // const reaGroupTypeformId = 'ovMDsc'
  // const basePass = 'LetMe!nN0w'
  const {content, scores} = props

  // useEffect(() => {
  //   if(!authState.isAuthenticated) {
  //     // When user isn't authenticated, forget any user info
  //     // setUserInfo(null);
  //   } else {
  //     authService.getUser().then( info => {
  //       // const user = api.createOktaUser( info.email )
  //       // props.fetchUserData( reaGroupTypeformId )
  //       // setUserInfo( info )
  //       // const oktaUserInfo = info
  //       // this.state.oktaUserInfo = oktaUserInfo
  //       // let user = props.createOktaUser( info.email, basePass )
  //       // console.log( "user created/instantiated: " + user.toString() )
  //     });
  //   }
  // }, [authState, authService]); // Update if authState changes
  //


  // firebase.auth().onAuthStateChanged( function () {
  //   console.log( "authentication observer called..." )
  // } )

  // if( !userInfo ){
  if ( !authState.isAuthenticated || !props.createOktaUserSuccess || !scores ) {
    return (
      <div className="page-profile">
        <Toolbar title={"My Results"} oktaAuthService={authService} />
        <LoadingScreen />
      </div>
      // <div>
      //   <p>Fetching user-specific benny button data...</p>
      // </div>
      // <LoadingScreen />
    );
  }

  return (
    <div className="page-profile">
        <Toolbar title={"My Results"} oktaAuthService={authService} />
        <WellbeingChart />
        {scores && <FactorLinks scores={scores} content={content}/>}
    </div>
  )

}

// class Profile extends Component {
//   render () {
//     const {content, scores} = this.props
//
//     return (
//       <div className="page-profile">
//         <Toolbar title={"My Results"} />
//         <WellbeingChart />
//         {scores && <FactorLinks scores={scores} content={content}/>}
//       </div>
//     )
//   }
// }

const mapDispatchToProps = {
  // appReadyChanged,
  // fetchContent,
  fetchOktaUserData,
  fetchUserDataSuccess,
  fetchContentSuccess,
  createOktaUser,
  createOktaUserSuccess,
  // createOktaUserFailed,
  firebaseUserChanged,
  signOut
}

const mapStateToProps = state => ({
  user: state.user,
  scores: state.scores,
  content: state.content,
})

export default connect(mapStateToProps,mapDispatchToProps)(Profile)
