import React, { Component } from 'react'
import { connect } from 'react-redux'
import constants from '../constants'
import { wellbeingInfoToggled } from '../actions'

import TooltipButton from './TooltipButton'
import WellbeingInfo from './WellbeingInfo'

class WellbeingChart extends Component {

  renderInfo = () => {
    return (
      <WellbeingInfo />
    )
  }

  renderChart = (scores, content) => {
    const cacheHack = '09062018'
    return (
      <div className="chart">
        <img src={`images/wedge-${scores.work.classification.toLowerCase()}-${cacheHack}.svg`} alt="Work" className="wedge wedge--work"/>
        <img src={`images/wedge-${scores.grow.classification.toLowerCase()}-${cacheHack}.svg`} alt="Grow" className="wedge wedge--grow"/>
        <img src={`images/wedge-${scores.stressMastery.classification.toLowerCase()}-${cacheHack}.svg`} alt="Stress Mastery" className="wedge wedge--stressMastery"/>
        <img src={`images/wedge-${scores.rest.classification.toLowerCase()}-${cacheHack}.svg`} alt="Rest" className="wedge wedge--rest"/>
        <img src={`images/wedge-${scores.move.classification.toLowerCase()}-${cacheHack}.svg`} alt="Move" className="wedge wedge--move"/>
        <img src={`images/wedge-${scores.nourish.classification.toLowerCase()}-${cacheHack}.svg`} alt="Nourish" className="wedge wedge--nourish"/>
        <img src={`images/wedge-${scores.mindfulAction.classification.toLowerCase()}-${cacheHack}.svg`} alt="Mindful Action" className="wedge wedge--mindfulAction"/>
        <img src={`images/wedge-${scores.connect.classification.toLowerCase()}-${cacheHack}.svg`} alt="Connect" className="wedge wedge--connect" />
        <img src={`images/chart-overlay-${cacheHack}.svg`} alt="wellbeing-chart" className="chart-overlay"/>

        <div className="wellbeing-chart__labels">
          <div className="wellbeing-chart__label wellbeing-chart__label--work">{content.factors[constants.factors.work.name].title}</div>
          <div className="wellbeing-chart__label wellbeing-chart__label--grow">{content.factors[constants.factors.grow.name].title}</div>
          <div className="wellbeing-chart__label wellbeing-chart__label--stressMastery">{content.factors[constants.factors.stressMastery.name].title}</div>
          <div className="wellbeing-chart__label wellbeing-chart__label--rest">{content.factors[constants.factors.rest.name].title}</div>
          <div className="wellbeing-chart__label wellbeing-chart__label--move">{content.factors[constants.factors.move.name].title}</div>
          <div className="wellbeing-chart__label wellbeing-chart__label--nourish">{content.factors[constants.factors.nourish.name].title}</div>
          <div className="wellbeing-chart__label wellbeing-chart__label--mindfulAction">{content.factors[constants.factors.mindfulAction.name].title}</div>
          <div className="wellbeing-chart__label wellbeing-chart__label--connect">{content.factors[constants.factors.connect.name].title}</div>
        </div>
      </div>
    )
  }

  render () {
    const { scores, content, wellbeingInfoVisible } = this.props

    return (
      <div className="wellbeing-chart">
        <TooltipButton onClicked={this.props.wellbeingInfoToggled}/>
        {
          wellbeingInfoVisible
          ? this.renderInfo()
          : this.renderChart(scores, content)
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  scores: state.scores,
  content: state.content,
  wellbeingInfoVisible: state.wellbeingInfoVisible
})

const mapDispatchToProps = {
  wellbeingInfoToggled
}

export default connect(mapStateToProps, mapDispatchToProps)(WellbeingChart)
