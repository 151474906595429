import React, { Component } from 'react'
import { connect } from 'react-redux'

import ContentGeneral from '../components/ContentGeneral'
import Toolbar from '../components/Toolbar'

import LoadingFullscreen from '../components/LoadingFullscreen'

class Info extends Component {

  backgroundImage = image => {
    return image
      ? {backgroundImage: `url(${image.file.url})`}
      : null
  }

  renderContent = content => {
    return (
      <div>
        <ContentGeneral
          title={content.aboutWellbeingTitle}
          description={content.aboutWellbeing}
          backgroundImage={this.backgroundImage(content.aboutWellbeingImage)}
        />
        <ContentGeneral
          title={content.aboutYourReportTitle}
          description={content.aboutYourReport}
          backgroundImage={this.backgroundImage(content.aboutYourReportImage)}
        />
        <ContentGeneral
          title={content.howYouMightFeelTitle}
          description={content.howYouMightFeel}
          backgroundImage={this.backgroundImage(content.howYouMightFeelImage)}
        />
        <ContentGeneral
          title={content.aboutYourResultsTitle}
          description={content.aboutYourResults}
          backgroundImage={this.backgroundImage(content.aboutYourResultsImage)}
        />
        <ContentGeneral
          title={content.returnOnVitalityTitle}
          description={content.returnOnVitality}
          backgroundImage={this.backgroundImage(content.returnOnVitalityImage)}
        />
        <ContentGeneral
          title="Disclaimer"
          description={content.disclaimer}
        />
        <ContentGeneral
          title="Terms & Conditions"
          description={content.termsAndConditions}
        />
      </div>
    )
  }

  render () {
    const { content } = this.props
    // const { authState, ready, fetchContentComplete, fetchUserDataComplete, user } = this.props

    // if ( authState.isAuthenticated && ready && !(fetchContentComplete && fetchUserDataComplete) ) {
    if ( !content ) {
      return <LoadingFullscreen />
    }

    return (
      <div className="page-info">
        <Toolbar />
        {content && this.renderContent(content)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  content: state.content && state.content.general
})

export default connect(mapStateToProps)(Info)
