import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Security, useOktaAuth } from '@okta/okta-react';
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer, { DEFAULT_STATE } from './state/index'
import rootSaga from './sagas'
import App from './App'
import ErrorModal from './components/ErrorModal'
import Raven from 'raven-js'
import config from "./config";

const sagaMiddleWare = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let environment = ''
if (window.location.host.includes('app-test')) environment = 'test'
else if (window.location.host.includes('app')) environment = 'production'
else environment = 'development'

const dsn = environment === 'development' ? null : 'https://6895d489181d4aaa9c3645154e3487fb@sentry.io/1197250'

Raven
  .config(dsn)
  .install()
  .setTagsContext({
    environment: environment,
    client: true
  })

const store = createStore(rootReducer,
  DEFAULT_STATE,
  composeEnhancers(applyMiddleware(sagaMiddleWare))
)

sagaMiddleWare.run(rootSaga)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, stack: error.stack });
    Raven.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      // return <ErrorModal message='Oops, unfortunately something went a little bit wrong... 😱' stack={this.state.stack}/>;
      return <ErrorModal message='Unfortunately something went a little bit wrong... The Benny Button scientists might still be busy creating your wellbeing and performance profile.' stack={this.state.stack}/>;
    }
    return this.props.children;
  }
}

ReactDOM.render((
  <Provider store={store}>
    <Security
      {...config.oidc}
    >
      <ErrorBoundary>
        <Router>
          <App/>
        </Router>
      </ErrorBoundary>
    </Security>
  </Provider>
), document.getElementById('root'))
