import React from 'react'
import Error from './Error'

const ErrorModal = ({message, stack}) => {
  return (
    <div className="error-modal">
      <div className="error-modal__background" />
      <Error message={message} stack={stack}/>
    </div>
  )
}

export default ErrorModal
