import React from 'react'

const Tab = ({label, onClick, isActive}) => {

  return (
    <div className={`tab ${isActive ? 'active' : ''}`}>
      <h5><a onClick={() => onClick(label)}>{label}</a></h5>
    </div>
  )
}

export default Tab
