import axios from 'axios'
import firebase from 'firebase'


class Api {

  constructor () {
    this.api = axios.create({
      timeout: 40000
    })
  }

  fetchContent = async () => {
    const authorization = await firebase.auth().currentUser.getIdToken()
    return this.api('/api/content', { headers: {authorization} })
  }

  fetchUserData = async formId => {
    const authorization = await firebase.auth().currentUser.getIdToken()
    return this.api('/api/users', { headers: {authorization}, params: { formId } })
  }


  fetchOktaUserData = async (formId, employeeId) => {
    const authorization = await firebase.auth().currentUser.getIdToken()
    return this.api('/api/users', { headers: {authorization}, params: { formId, employeeId } })
  }


  // createOktaUser = async (email, password, employeeId) => {
  //   try {
  //     let userPromise = signInOrCreateFirebaseUser(email, password, firebase.auth(), employeeId)
  //     return await userPromise
  //   } catch ( error ) {
  //     console.error( error )
  //   }
  //
  //   // if ( createdOktaUser ) {
  //   //   const authorization = await firebase.auth().currentUser.getIdToken()
  //   //   // this.api.get()
  //   // }
  //   // return createdOktaUser
  // }
  // createOktaUser = async ( email, password, employeeId ) => {
  //   signInOrCreateFirebaseUser(email, password, firebase.auth(), employeeId).then( function( feuerBasisKunde ) {
  //       return feuerBasisKunde
  //   } ).catch( function( error ) {
  //     console.error( error )
  //   } )
  // }
  createOktaUser = ( email, password, employeeId ) => {
    return signInOrCreateFirebaseUser( email, password, firebase.auth(), employeeId )
  }



  // createOktaUserBlocking = (email, password, employeeId) => {
  //   try {
  //     let userPromise = signInOrCreateFirebaseUser(email, password, firebase.auth(), employeeId)
  //     return await userPromise
  //   } catch ( error ) {
  //     console.error( error )
  //   }
  // }

  fetchUserPriorities = async () => {
    const authorization = await firebase.auth().currentUser.getIdToken()
    return this.api.get('/api/users/priorities', { headers: {authorization} })
  }

  updateUserPriorities = async priorities => {
    const authorization = await firebase.auth().currentUser.getIdToken()
    return this.api.post('/api/users/priorities', { priorities }, { headers: {authorization} })
  }

}


function signInOrCreateFirebaseUser( email, password, auth, employeeId ) {
  // let firebaseOktaPromise = new Promise( (resolve, reject) => {

    if ( auth.currentUser ==  null ) {
      auth.signInWithEmailAndPassword(email, password).then(user =>
        {
          loginUserSuccess(user)
          return user
        })
        .catch((error) => {
          console.error(error);
          console.log( "Unable to log in user into Firebase for " + email + " and " + employeeId + "..." )
          auth.createUserWithEmailAndPassword( email, password ).then(user =>
            {
              userCreatedSuccess(user)
              return user
            })
            .catch(function (error) {
              console.error( error.message )
              console.log( "Unable to create a user into Firebase for " + email + " and " + employeeId + "..." )
            })
        })
      console.log( "Got a valid REA Firebase user..." )
    } else {
      // resolve( auth.currentUser )
      return ( auth.currentUser )
    }
  // })
  // console.log( "Firebase user set for " + email + " and employee-Id " + employeeId )
  // return user
  // return await firebaseOktaPromise
}



function loginUserSuccess( user ) {
  console.log( "User " + user.user.email + " has logged in..." )
}


function userCreatedSuccess( user ) {
  console.log( "User " + user.user.email + " has been created successfully..." )
}

// async function signInOrCreateFirebaseUser( email, password, auth, employeeId ) {
//   let firebaseOktaPromise = new Promise( (resolve, reject) => {
//     if ( auth.currentUser ==  null ) {
//       let signedInUser = auth.signInWithEmailAndPassword(email, password).then(function () {
//         resolve( signedInUser )
//         return signedInUser
//       }).catch(function (error) {
//         console.error( error.message )
//         let createdUser = createFirebaseUser(email, password, auth, employeeId).then(function () {
//           resolve(createdUser)
//           return createdUser
//         }).catch(function (error) {
//           console.error( error.message )
//           reject()
//         })
//       })
//     } else {
//       resolve( auth.currentUser )
//       return ( auth.currentUser )
//     }
//   })
//   // console.log( "Firebase user set for " + email + " and employee-Id " + employeeId )
//   // return user
//   return await firebaseOktaPromise
// }

// async function getCurrentFirebaseUser( auth ) {
//   return new Promise( ( resolve, reject ) => {
//     const unsubscribe = auth.onAuthStateChanged( user => {
//       unsubscribe();
//       resolve( user );
//     }, reject);
//   });
// }

async function signInFirebaseUser( email, password, auth ) {
  return new Promise( ( resolve, reject ) => {
    let signedInUser = auth.signInWithEmailAndPassword( email, password )
    resolve( signedInUser )
  });
}


async function createFirebaseUser( email, password, auth, employeeId ) {
  return await new Promise( ( resolve, reject ) => {
    let createdUser = auth.createUserWithEmailAndPassword( email, password )
    resolve( createdUser )
  });
}
export default new Api()
