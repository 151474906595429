module.exports = {
  firebase: {
    apiKey: "AIzaSyD0WhnteksEhff0lVALq8J52vK17T44wU0",
    authDomain: "benny-button-internal-dev.firebaseapp.com",
    databaseURL: "https://benny-button-internal-dev.firebaseio.com",
    projectId: "benny-button-internal-dev",
    storageBucket: "benny-button-internal-dev.appspot.com",
    messagingSenderId: "130593965220",
  },
  oidc: {
    // clientId: '0oajz1ab72Ax2ImRb4x6', // localhost
    // clientId: '0oao929fwBTRwbgZz4x6', // rea-profiler.bennybutton.com test
    clientId: '0oat8kruq64P4u5K70h7', // REA OKTA DEV (rea.oktapreview.com)
    // redirectUri: 'http://localhost:8080/implicit/callback', // localhost
    redirectUri: 'http://localhost:3000/implicit/callback', // localhost tuasonsoftware and REA OKTA DEV
    // redirectUri: 'https://rea-profiler-dot-benny-button-dev-401d6.uc.r.appspot.com/implicit/callback', // tuasonsoftware
    // redirectUri: 'https://rea-profiler.bennybutton.com/implicit/callback', // rea-profiler.bennybutton.com test
    // issuer: 'https://dev-812847.okta.com/oauth2/default',
    issuer: 'https://rea.oktapreview.com/oauth2/default', // REA OKTA DEV (rea.oktapreview.com)
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    // disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    disableHttpsCheck: false,
  },
  oktaUsersBasePass: 'LetMe!nN0w'
}
