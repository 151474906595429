import Types from '../types'

export const firebaseUserChanged = user => ({type: Types.FIREBASE_USER_CHANGED, user})
export const appReadyChanged = isReady => ({type: Types.APP_READY_CHANGED, isReady})
export const signOut = () => ({type: Types.SIGN_OUT})
export const fetchContent = () => ({type: Types.FETCH_CONTENT})
export const fetchContentSuccess = data => ({type: Types.FETCH_CONTENT_SUCCESS, data})
export const fetchContentFailed = error => ({type: Types.FETCH_CONTENT_FAILED, error})
export const fetchUserData = formId => ({type: Types.FETCH_USER_DATA, formId})
export const fetchOktaUserData = (formId, employeeId) => ({type: Types.FETCH_OKTA_USER_DATA, formId, employeeId})
export const createOktaUser = (email, password, employeeId) => ({type: Types.CREATE_OKTA_USER, email, password, employeeId})
export const createOktaUserSuccess = user => ({type: Types.CREATE_OKTA_USER_SUCCESS, user})
export const createOktaUserFailed = error => ({type: Types.CREATE_OKTA_USER_FAILED, error})
export const fetchUserDataSuccess = data => ({type: Types.FETCH_USER_DATA_SUCCESS, data})
export const fetchUserDataFailed = error => ({type: Types.FETCH_USER_DATA_FAILED, error})
export const fetchUserPriorities = () => ({type: Types.FETCH_USER_PRIORITIES})
export const fetchUserPrioritiesSuccess = data => ({type: Types.FETCH_USER_PRIORITIES_SUCCESS, data})
export const fetchUserPrioritiesFailed = error => ({type: Types.FETCH_USER_PRIORITIES_FAILED, error})
export const updateUserPriorities = () => ({type: Types.UPDATE_USER_PRIORITIES})
export const updateUserPrioritiesSuccess = data => ({type: Types.UPDATE_USER_PRIORITIES_SUCCESS, data})
export const updateUserPrioritiesFailed = error => ({type: Types.UPDATE_USER_PRIORITIES_FAILED, error})
export const factorDefinitionExpanded = () => ({type: Types.FACTOR_DEFINITION_EXPANDED})
export const factorDefinitionCollapsed = () => ({type: Types.FACTOR_DEFINITION_COLLAPSED})
export const windowScrollChanged = scrollY => ({type: Types.WINDOW_SCROLL_CHANGED, scrollY})
export const wellbeingInfoToggled = () => ({type: Types.WELLBEING_INFO_TOGGLED})
export const setPriority = subFactor => ({type: Types.SET_PRIORITY, subFactor})
export const unsetPriority = subFactor => ({type: Types.UNSET_PRIORITY, subFactor})
export const setActiveLearningLockerTab = tab => ({type: Types.SET_ACTIVE_LEARNING_LOCKER_TAB, tab})
