import React, { Component } from 'react'
import prefixAll from 'inline-style-prefixer/static'

import ResizeAware from 'react-resize-aware'

const Meter = ({width, height, radius, percentage}) => {
  let position = (percentage / 100) * width
  position = Math.min(position, (width - radius))
  position = Math.max(position, radius)
  const clipPathStyle = prefixAll({clipPath: `circle(${radius}px at ${position}px)` })

  return (
    <div className="marker" style={clipPathStyle} />
  )
}



class FactorScoreIndicator extends Component {
  calculateScorePercentage() {
    const {score, min, drainerMax, driverMin, max} = this.props
    let scorePercent = 0
    let rangeSize = (100/3)

    if (score >= min && score <= drainerMax) {
      scorePercent = (score / drainerMax) * rangeSize
    } else if (score > drainerMax && score <= driverMin) {
      scorePercent = rangeSize + (((score - drainerMax)/(driverMin - drainerMax)) * rangeSize)
    } else if (score > driverMin && score <= max) {
      scorePercent = (rangeSize * 2) + (((score - driverMin)/(max - driverMin)) * rangeSize)
    } else {
      console.error('Score is outside of min/max bounds', {score, min, drainerMax, driverMin, max})
    }

    scorePercent = Math.min(scorePercent, 100)
    scorePercent = Math.max(scorePercent, 0)

    return scorePercent
  }

  render () {
    const {score, min, drainerMax, driverMin, max, isSmall} = this.props
    if (!score || !min || !drainerMax || !driverMin || !max) return null
    const markerRadius = isSmall ? 3.5 : 7.5
    let scorePercent = this.calculateScorePercentage()

    return (
      <div className="factor-score-indicator">
        <div className="bar"/>
        <ResizeAware style={{ position: 'relative' }}>
          <Meter radius={markerRadius} percentage={scorePercent} />
        </ResizeAware>
      </div>
    )
  }
}



export default FactorScoreIndicator
