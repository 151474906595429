import React from 'react'
import {DateTime} from 'luxon'

const Error = ({message, stack}) => {
  const now = DateTime.local()
  return (
    <div className="error">
      <div className="error__message">
        {message}
      </div>
      {stack &&
        <div>
          <br/>
          <p>
            Take a breather for a minute or so and then <a href="https://rea-profiler.bennybutton.com/info">click here</a> to try again reloading your profile.
          </p>
          <p>
            If your profile still does not load after a few minutes, please email us at <a href="mailto:hello@bennybutton.com">hello@bennybutton.com</a> quoting your REA Employee Number.</p>
          <p>
            <small>A detailed report of this error has been sent to the Benny Button scientists.</small>
          </p>
          <small>{now.toISO()}</small>
          <pre>{stack}</pre>
        </div>
      }
    </div>
  )
}

export default Error
