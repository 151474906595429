import React from 'react'
import changeCase from 'change-case'

import ArticleCard from './ArticleCard'

const ArticleRow = ({title, articles}) => {
  const titleFormatted = changeCase.sentence(title)

  return (
    <div className="article-row">
      <div className="article-row__title-bar">
        <h5 className="text-subdued title">{titleFormatted}</h5>
        <div className="horizontal-rule"/>
      </div>
      <div className="article-row__content">
        {
          Object.entries(articles)
            .map(([id, article]) => <ArticleCard key={id} article={article}/>)
        }
      </div>
    </div>
  )
}

export default ArticleRow
