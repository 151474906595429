import React, { Component } from 'react'
import { connect } from 'react-redux'

import Tab from './Tab'

class TabGroup extends Component {
  render () {
    const { tabs, onClick, activeLearningLockerTab } = this.props

    return (
      <div className="tab-group">
        {tabs.map(tab => <Tab key={tab.label} label={tab.label} onClick={onClick} isActive={tab.label === activeLearningLockerTab}/>)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activeLearningLockerTab: state.activeLearningLockerTab
})

export default connect(mapStateToProps)(TabGroup)
