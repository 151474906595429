import React from 'react'
import { Link } from 'react-router-dom'
import constants from '../constants'

const FactorLink = ({order, title, path, classification, iconPath , isPriority}) => (
  <div className="factor-link">
    <Link to={`/profile${path}`}>
      <div className={`icon-button icon-button--${classification.toLowerCase()}`}>
        <img src={iconPath} alt={`${title} icon`}/>
        {
          isPriority &&
          <div className="priority-icon-container">
            <img className="priority-icon" src={constants.icons.starFilled.path} alt="priority" />
          </div>
        }
      </div>
      <div className="factor-link__title">{title}</div>
    </Link>
  </div>
)

export default FactorLink
