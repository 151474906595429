import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import constants from '../constants'

import SubFactor from './SubFactor'
import Toolbar from './Toolbar'
import FactorTitle from './FactorTitle'
import FactorScoreIndicator from './FactorScoreIndicator'
import { factorDefinitionExpanded, factorDefinitionCollapsed } from '../actions'

class Factor extends Component {
  componentWillMount () {
    this.props.factorDefinitionCollapsed()
  }

  descriptionSource = (content, count) => {
    switch(count) {
      case 0: return content.challengesZero
      case 1: return content.challengesOne
      default: return content.challengesMultiple
    }
  }

  renderDefinition = (title, definition) => {
    const {definitionExpanded} = this.props
    const className = `collapsible ${definitionExpanded ? 'expanded' : 'collapsed'}`
    return (
      <div className="factor__definition">
        <h3 className="subheader">What is {title}?</h3>
        <div className={className}>
          <ReactMarkdown source={definition}/>
          {!definitionExpanded &&
            <a className="button-read-more"
               onClick={() => this.props.factorDefinitionExpanded()}>&hellip; {constants.READ_MORE}</a>
          }
          </div>
      </div>
    )
  }

  renderClassification = (content, classification) => {
    const contentKey = `${classification.toLowerCase()}Description`
    return (
      <div className="factor__classification">
        <h3 className="subheader">Your result is:</h3>
        <h4>{classification}</h4>
        <ReactMarkdown source={content[contentKey]}/>
      </div>
    )
  }

  renderChallenges = (challenges, content) => {
    if (!(challenges && content.challengesZero)) return null
    const descriptionSource = this.descriptionSource(content, challenges.length)
    const description = descriptionSource.replace(constants.CONTENT_REPLACEMENT_TOKEN, challenges.map(c => `'${c}'`).join(', '))

    return (
      <div className="factor__challenges">
        <h4>Challenges</h4>
        <ReactMarkdown source={description}/>
      </div>
    )
  }

  renderSubFactors (subFactors, parentFactor) {
    if (!subFactors) return null

    return Object.entries(subFactors)
      .map(
        ([key, subFactor]) =>
          <SubFactor
            key={key}
            {...subFactor}
            parentFactor={parentFactor}/>
      )
  }

  render () {
    const {factor, content, priorities} = this.props
    const factorContent = content.factors[factor.name]
    const isPriority = priorities.find(p => p.parentFactor === factor.name)

    return (
      <div className="factor">
        <Toolbar backLink={constants.routes.HOME} />
        <FactorTitle
          name={factor.name}
          title={factorContent.title}
          classification={factor.classification.toLowerCase()}
          isPriority={isPriority}
        />

        <FactorScoreIndicator
          score={factor.score}
          min={factor.scoreMin}
          drainerMax={factor.drainerMax}
          driverMin={factor.driverMin}
          max={factor.scoreMax}
        />

        {this.renderDefinition(factorContent.title, factorContent.definition)}
        {this.renderClassification(factorContent, factor.classification)}
        {this.renderChallenges(factor.challenges, factorContent)}
        {this.renderSubFactors(factor.subFactors, factor.name)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  content: state.content,
  priorities: state.priorities,
  definitionExpanded: state.definitionExpanded
})

const mapDispatchToProps = {
  factorDefinitionExpanded,
  factorDefinitionCollapsed
}

export default connect(mapStateToProps, mapDispatchToProps)(Factor)
