import React, { Component, useState, useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Route, useHistory, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import firebase from 'firebase'
// import queryString from 'query-string'
import api from './api'
import { ToastContainer } from 'react-toastify'
import {
  appReadyChanged,
  fetchContent,
  fetchContentSuccess,
  createOktaUser,
  createOktaUserSuccess,
  fetchOktaUserData,
  fetchUserData,
  fetchUserDataSuccess,
  firebaseUserChanged,
  signOut
} from './actions'
import constants from './constants'
import config from './config'

import 'react-toastify/dist/ReactToastify.min.css'
import './styles/app.css'

import { withOktaAuth, SecureRoute, LoginCallback } from '@okta/okta-react';
import ReaLoginComponent from './pages/OktaLogin';
import Profile from './pages/Profile'
import Factor from './components/Factor'
import LearningLocker from './pages/LearningLocker'
import Info from './pages/Info'
import Nav from './components/Nav'
import Toolbar from './components/Toolbar'
import LoadingFullscreen from './components/LoadingFullscreen'
import ErrorModalHtml from './components/ErrorModalHtml'
import ScrollToTop from './ScrollToTop'



// async function checkUser() {
//   console.log( "async -> check okta user..." )
//   if (this.props.authState.isAuthenticated && !this.state.userInfo) {
//     console.log( "async -> check okta user..." )
//     const userInfo = await this.props.authService.getUser();
//     this.state.userInfo = userInfo
//     console.log( "async -> got okta user:" + userInfo + "..." )
//   } else {
//     console.log( "No okta user authenticated: " + this.props.authState )
//   }
// }


class App extends Component {

  constructor (props) {
    super(props)
    if (!firebase.apps.length) {
      firebase.initializeApp(config.firebase)
    }
    // firebase.analytics()

    this.state = { userInfo: null };
    // this.checkUser = checkUser.bind(this);
  }


  componentWillMount () {
    //make sure we remove the cache to start with if there...
    this.checkAndInvalidateLocalStorage( constants.SCORES )
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged( this.onAuthStateChanged )
  }

  componentDidMount() {
    // this.checkUser()
  }


  checkAndInvalidateLocalStorage = ( key ) => {
    if ( localStorage.getItem( key ) !== null ) {
      localStorage.removeItem( key );
      console.info( `Cached item '${key}' removed from localStorage at start-up...` )
    } else {
      console.warn( `No cached data with key '${key}' found in localStorage / browser cache... Nothing to remove!` )
    }
  }

  loadCachedData = (key, success) => {
    try {
      let cachedData = localStorage.getItem(key)
      if (!cachedData) return
      const data = JSON.parse(cachedData)
      success(data)
    } catch (err) {
      console.warn(`Cached item '${key}' could not be restored, removing from cache`, err)
      localStorage.removeItem(key)
    }
  }


  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }


  onAuthStateChanged = firebaseUser => {
    const {authState, authService} = this.props
    const {userInfo} = this.state
    // let formIdInURL = queryString.parse(window.location.search)
    let form_id = 'ovMDsc'

    if ( authState.isAuthenticated ) {
      if ( !userInfo ) {
        authService.getUser().then( (info) => {
          let oktaUserInfo = info ? info : null
          this.state.userInfo = oktaUserInfo
          let employeeNumber = oktaUserInfo.preferred_username
          let emailAddress = oktaUserInfo.email
          this.state.oktaUserAuthenticated = true
          var sleepInMillis = 10

          let user = firebaseUser ? firebaseUser.toJSON() : null
          if ( !user ) {
            sleepInMillis = 5000
            var neuerFeuerBasisBenutzer = null
            neuerFeuerBasisBenutzer = api.createOktaUser( emailAddress, 'LetMe!nN0w', employeeNumber )
            this.props.createOktaUserSuccess( neuerFeuerBasisBenutzer )
          }

          this.sleep( sleepInMillis ).then ( () => {
            this.props.fetchUserDataSuccess()
            // this.loadCachedData(constants.SCORES, this.props.fetchUserDataSuccess)
            // this.loadCachedData(constants.CONTENT, this.props.fetchContentSuccess)
            this.props.fetchOktaUserData( form_id, employeeNumber )
            this.props.fetchContent()
            this.props.firebaseUserChanged(user)
            this.props.appReadyChanged(true)
          } )
        } )
      }
    } else {
      this.state.userInfo = null
    }
    this.props.appReadyChanged(true)
  }


  // renderFactorRoutes () {
  //   return Object.entries(constants.factors)
  //     .map(([key, factor]) =>
  //       <Route
  //         key={key}
  //         path={`/profile${factor.path}`}
  //         render={props => <Factor {...props} factor={this.props.scores[factor.name]}/>}
  //       />)
  // }


  /**
   *
   */
  renderError = (error) => {
    let message = this.props.error.response && this.props.error.response.data
      ? this.props.error.response.data
      : 'Unknown error. Please try again later'

    message = error && error.message ? error.message : message

    return (
        <div>
          <Toolbar {...this.props}/>
          <ErrorModalHtml message={message} />
        </div>
    )
  }


  // renderApp() {
  //   return (
  //     <div className="nav-container">
  //       <ToastContainer />
  //       {/*<Container text style={{ marginTop: '7em' }}>*/}
  //       {/*<Route path="/" exact component={LoadingFullscreen} />*/}
  //       <Route path="/implicit/callback" component={LoginCallback} />
  //       <Route path="/login" exact component={ReaLoginComponent} />
  //       <SecureRoute exact path={constants.routes.HOME} render={() => <Redirect to={constants.routes.INFO} />} />
  //       <SecureRoute exact path={constants.routes.LEARNING_LOCKER} component={LearningLocker}/>
  //       <SecureRoute exact path={constants.routes.INFO} component={Info}/>
  //       <SecureRoute exact path={constants.routes.PROFILE} component={Profile} />
  //       {this.renderFactorRoutes()}
  //       <Nav/>
  //     </div>
  //   )
  // }

  renderApp () {
    return (
        <HasAccessToRouter {...this.props}/>
    )
  }

  // renderLogin = () => {
  //   // const history = useHistory();
  //   return (
  //     <Redirect to={{ pathname: '/login' }}/>
  //   )
  // }

  render () {
    const {authState, ready, fetchContentComplete, fetchUserDataComplete, user} = this.props
    // const {oktaUserAuthenticated, createOktaUserComplete} = this.state

    if ( !ready || authState.isPending ) return <LoadingFullscreen />
    // if ( !ready || authState.isPending || !user || !fetchUserDataComplete ) return <LoadingFullscreen />
    // if (!ready || !user) return <LoadingFullscreen />
    // if ( ready && oktaUserAuthenticated && !authState.isPending && !authState.isAuthenticated ) {
    //   return <ReaLoginComponent />
    // }
    // if ( !user ) return <LoadingFullscreen/>
    //if (ready && !authState.isAuthenticated) return <ReaLoginComponent />
    // if ( authState.isAuthenticated && ready && !(fetchContentComplete && fetchUserDataComplete) ) {
    // // //   // if ( !authState.isAuthenticated )
    // // //   //   return <ReaLoginComponent />
    // // //   // else
    //     return <LoadingFullscreen/>
    // }

    return (
      // <Security
      //   {...config.oidc}
      // >
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <ScrollToTop>
            {this.props.error
              ? this.renderError()
              : this.renderApp()
            }
          </ScrollToTop>
        </Router>
      // </Security>
    )
  }
}

const mapStateToProps = state => ({
  ready: state.ready,
  scores: state.scores,
  content: state.content,
  error: state.error,
  fetchContentComplete: state.fetchContentComplete,
  fetchUserDataComplete: state.fetchUserDataComplete,
  oktaUserAuthenticated: state.oktaUserAuthenticated,
  user: state.user
})

const mapDispatchToProps = {
  appReadyChanged,
  fetchContent,
  createOktaUser,
  createOktaUserSuccess,
  fetchOktaUserData,
  fetchUserData,
  fetchUserDataSuccess,
  fetchContentSuccess,
  firebaseUserChanged,
  signOut
}


const HasAccessToRouter = (props) => {
  // const history = useHistory();
  // const [globalProps] = useState(props)
  // const [appProps] = useState(props)
  // const [scoresFromProps, setScoresFromProp] = useState(props.scores)
  // console.log( "Received app properties with score: " + globalProps.score )
  // console.log( "local storage scores: " + localStorage.scores )

  // const onAuthRequired = () => {
  //   // console.log( "history.push(/login)" )
  //   history.push('/login');
  // };

  // useEffect(() => {
  //   return () => {
  //     effect
  //   };
  // }, [input]);


  const readScoreElement = ( localStorageScores, propsScores, factorKey ) => {
    console.info( "Reading score elements from local storage or properties..." )
    if ( propsScores ) {
      let scores = JSON.parse( propsScores )
      return scores[factorKey]
    } else if ( localStorageScores ) {
      let scores = JSON.parse( localStorageScores )
      return scores[factorKey]
    } else {
      console.warn( "No properties nor localstorage scores were set, returning defaults..." )
      return '"scoreMin":1,"drainerMax":2.5,"driverMin":3.75,"scoreMax":5,"score":4.8,"classification":"Driver"'
    }
  }


  const renderFactorRoutes = (props) => {
    return Object.entries(constants.factors)
      .map(([key, fac]) =>
        <Route
          key={key}
          path={`/profile${fac.path}`}
          render={props => <Factor {...props} factor={readScoreElement( localStorage.scores, props.scores, fac.name )}/>}
          // render={props => <Factor props={props} factor={props.scores[fac.name]}/>}
          // render={props => {
          //   if ( props.score ) {
          //     <Factor props={props} factor={props.scores[fac.name]}/> }
          //   else {
          //     <Factor props={props} factor={localStorage.scores[fac.name]}/>
          //   }} }
          // render={globalProps => <Factor {...globalProps} factor={localStorage.scores[factor.name]}/>}
        />)
  }

  // const renderFactorRoutes = () => {
  //   return Object.entries(constants.factors)
  //     .map(([key, factor]) =>
  //       <Route
  //         key={key}
  //         path={`/profile${factor.path}`}
  //         render={props => <Factor {...props} factor={localStorage.scores[factor.name]}/>}
  //       />)
  // }

  return (
    // <Security
    //   {...config.oidc}
    //   onAuthRequired={onAuthRequired()}
    // >
      <div className="nav-container">
        <ToastContainer />
        {/*<Container text style={{ marginTop: '7em' }}>*/}
        <Route path="/" exact component={LoadingFullscreen} />
        <Route path="/implicit/callback" component={LoginCallback} />
        <Route path="/login" exact component={ReaLoginComponent} />
        <SecureRoute exact path={constants.routes.HOME} render={() => <Redirect to={constants.routes.INFO} />} />
        <SecureRoute exact path={constants.routes.LEARNING_LOCKER} component={LearningLocker}/>
        <SecureRoute exact path={constants.routes.INFO} component={Info}/>
        <SecureRoute exact path={constants.routes.PROFILE} component={Profile} />
        {renderFactorRoutes(props)}
        <Nav/>
      </div>
    // </Security>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(App))
