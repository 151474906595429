import React from 'react'
import constants from '../constants'
import { connect } from 'react-redux'

const TooltipButton = ({onClicked, wellbeingInfoVisible}) => (
  <div className="tooltip-button" onClick={onClicked}>
    <img className="tooltip-button__background" src={constants.icons.speechBubble.path} alt="Info icon" />
    <img
      className={`tooltip-button__icon icon--close ${!wellbeingInfoVisible ? 'hide' : ''}`}
      src={constants.icons.x.path}
      alt="Close icon"
    />
    <img
      className={`tooltip-button__icon ${wellbeingInfoVisible ? 'hide' : ''}`}
      src={constants.icons.info.path}
      alt="Info icon"
    />
  </div>
)

const mapStateToProps = state => ({
  wellbeingInfoVisible: state.wellbeingInfoVisible
})

export default connect(mapStateToProps)(TooltipButton)
