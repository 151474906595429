import React, { Component } from 'react'
import { connect } from 'react-redux'
import camelCase from 'camel-case'
import { setActiveLearningLockerTab } from '../actions'
import constants from '../constants'
import { reduceEntriesToObject, isEmpty } from '../util'

import Toolbar from '../components/Toolbar'
import ArticleRow from '../components/ArticleRow'
import TabGroup from '../components/TabGroup'
import EmptyState from '../components/EmptyState'

class LearningLocker extends Component {

  transformByFactor = (articles) => {
    return Object.entries(articles)
      .reduce((accum, [id, val]) => {
        val.tags
          .map(camelCase)
          .forEach(tag => { accum[tag] = {...accum[tag], [id]: val} })

        return accum
      }, {})
  }

  switchView = (articles, filter) => {
    switch (filter) {
      case constants.PRIORITIES:
        return this.renderPriorities(articles)

      default:
        return this.renderAll(articles)
    }
  }

  renderAll = articles => {
    return (
      <div>
        {
          Object.entries(articles)
            .map(([factor, articles]) => <ArticleRow key={factor} title={factor} articles={articles}/>)
        }
      </div>
    )
  }

  renderPriorities = articles => {
    const {priorities} = this.props
    const priorityArticles = Object.entries(articles)
      .filter(([key]) => priorities.find(p => p.parentFactor === key))
      .reduce(reduceEntriesToObject, {})

    return isEmpty(priorityArticles)
      ? (
        <EmptyState title={constants.NO_PRIORITIES_TITLE} message={constants.NO_PRIORITIES_MESSAGE} />
      )
      : (
        <div>
          {
            Object.entries(priorityArticles)
              .map(([factor, articles]) => <ArticleRow key={factor} title={factor} articles={articles}/>)
          }
        </div>
      )
  }

  render () {
    const {articles, setActiveLearningLockerTab, activeLearningLockerTab} = this.props
    const articlesByFactor = this.transformByFactor(articles)
    const tabs = [
      {label: constants.ALL},
      {label: constants.PRIORITIES}
    ]

    return (
      <div className="page-learning-locker">
        <Toolbar/>
        <TabGroup tabs={tabs} onClick={setActiveLearningLockerTab}/>
        {articles && this.switchView(articlesByFactor, activeLearningLockerTab)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  articles: state.content && state.content.articles,
  activeLearningLockerTab: state.activeLearningLockerTab,
  priorities: state.priorities
})

const mapDispatchToProps = {
  setActiveLearningLockerTab
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningLocker)
