import Types from '../types'
import constants from '../constants'

export const DEFAULT_STATE = {
  ready: false,
  oktaUserAuthenticated: false,
  createOktaUserComplete: false,
  fetchContentComplete: false,
  fetchUserDataComplete: false,
  user: null,
  scores: null,
  content: null,
  error: null,
  definitionExpanded: false,
  windowScrolled: false,
  wellbeingInfoVisible: false,
  priorities: [],
  activeLearningLockerTab: constants.ALL
}

export default function update (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case Types.APP_READY_CHANGED:
      return {...state, ready: action.isReady}

    case Types.FIREBASE_USER_CHANGED:
      if (!action.user) return {...state, user: null}
      const {email, displayName} = action.user
      return {...state, user: {email, displayName}}

    case Types.FETCH_CONTENT_SUCCESS:
      return {...state, content: action.data, fetchContentComplete: true}

    case Types.FETCH_CONTENT_FAILED:
      return {...state, error: action.error, fetchContentComplete: true}

    case Types.FETCH_USER_DATA_SUCCESS:
      return {...state, scores: action.data, fetchUserDataComplete: true}

    case Types.FETCH_USER_DATA_FAILED:
      return {...state, error: action.error, fetchUserDataComplete: true}

    // case Types.CREATE_OKTA_USER:
    //   return {...state, error: action.error, createOktaUserSuccess: false}

    case Types.CREATE_OKTA_USER_SUCCESS:
      return {...state, content: action.data, createOktaUserSuccess: true}

    case Types.CREATE_OKTA_USER_FAILED:
      return {...state, error: action.error, createOktaUserSuccess: false}

    case Types.SIGN_OUT:
      return {...state, user: null}

    case Types.FACTOR_DEFINITION_EXPANDED:
      return {...state, definitionExpanded: true}

    case Types.FACTOR_DEFINITION_COLLAPSED:
      return {...state, definitionExpanded: false}

    case Types.WINDOW_SCROLL_CHANGED:
      return {...state, windowScrolled: action.scrollY > 10}

    case Types.WELLBEING_INFO_TOGGLED:
      return {...state, wellbeingInfoVisible: !state.wellbeingInfoVisible}

    case Types.SET_PRIORITY:
      const priorities = (state.priorities.length === constants.prioritiesMax)
        ? state.priorities.slice(1, constants.prioritiesMax).concat([action.subFactor])
        : state.priorities.concat([action.subFactor])
      return {...state, priorities}

    case Types.UNSET_PRIORITY:
      return {...state, priorities: state.priorities.filter(subFactor => subFactor.name !== action.subFactor.name)}

    case Types.SET_ACTIVE_LEARNING_LOCKER_TAB:
      return {...state, activeLearningLockerTab: action.tab}

    default:
      return state
  }
}
